import React from 'react'
import { useLottie } from "lottie-react";

import styles from "./LottieAnimation.module.css";

type Props = {
  animationJSON: any;
  loop: boolean;
}

export default function LottieAnimation(props: Props) {

   const optionsWalk = {
     animationData: props.animationJSON,
     loop: props.loop,
   };

   const { View } = useLottie(optionsWalk);
  
  return (
    <div className={styles.lottieAnimationHolder}>
      {View}
    </div>
  )
}