import React from 'react';
import { useLottie } from "lottie-react";
import groovyWalkAnimation from "./animations/groovyWalk.json";
import pwTestAnimation from "./animations/pwTest.json";
import bodyAnimation from "./animations/bodyAnimation.json";
import './App.css';
import LottieAnimation from './components/LottieAnimation/LottieAnimation';

function App() {
  return (
    <div className="App">
      <LottieAnimation animationJSON={pwTestAnimation} loop={true} />
    </div>
  );
}

export default App;
